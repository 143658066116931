import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import imageData from "../../assets/images/testal-log.png";
import { CloudContext } from "../../App";
import pointCloudData from "../../assets/data/pointCloudData.json";

const Header = () => {
  const { setOptions, setCurrentLocation } = useContext(CloudContext);
  const location = useLocation();
  const isNavDisabled = location.pathname.includes("view-cloud");

  return (
    <header>
      <Link to="/">
        <h1>
          <img alt="Testal logo" src={imageData} />
        </h1>
      </Link>
      <nav>
        <ul>
          {pointCloudData.map(item => (
            <li
              onClick={() => {
                if (!isNavDisabled) {
                  setOptions({ flyTo: item.position });
                  setCurrentLocation(item.position);
                }
              }}
              style={{
                cursor: isNavDisabled ? "not-allowed" : "pointer",
                opacity: isNavDisabled ? 0.3 : 1,
              }}
            >
              {item.short}
            </li>
          ))}
        </ul>
      </nav>
      {/* <a
        href="https://www.flaticon.com/free-icons/pin"
        title="pin icons"
        className="icon-attribution"
      >
        Pin icons created by Freepik - Flaticon
      </a> */}
    </header>
  );
};

export default Header;
