import L from "leaflet";

const Icon = L.icon({
  iconUrl: "../../assets/images/maps-and-flags.png",
  iconSize: [38, 95],
  iconAnchor: [22, 94],
  popupAnchor: [-3, -76],
});

export default Icon;
