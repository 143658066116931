import { useContext, useEffect, useRef, useState } from "react";
import { CloudContext } from "../../App";
import { useNavigate } from "react-router-dom";

const PotreeViewer = () => {
  const [viewer, setViewer] = useState();
  const [cesiumViewer, setCesiumViewer] = useState();
  const [loopActive, setLoopActive] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { context } = useContext(CloudContext);
  const ref = useRef();
  const Potree = window.Potree;
  const proj4 = window.proj4;
  const THREE = window.THREE;
  const Cesium = window.Cesium;
  const navigate = useNavigate();

  useEffect(() => {
    if (loaded) {
      return;
    }
    if (!ref.current) {
      return;
    }
    if (!context || !context.cloudUrl) {
      navigate("/");
    }

    if (!viewer) {
      const viewer = new Potree.Viewer(
        ref.current,
        context.enableProjection ? { useDefaultRenderLoop: false } : {}
      );
      setViewer(viewer);
    }

    if (!cesiumViewer) {
      const cesiumViewer = new Cesium.Viewer("cesiumContainer", {
        useDefaultRenderLoop: false,
        animation: false,
        baseLayerPicker: false,
        fullscreenButton: false,
        geocoder: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        timeline: false,
        navigationHelpButton: false,
        imageryProvider: Cesium.createOpenStreetMapImageryProvider({
          url: "https://a.tile.openstreetmap.org/",
        }),
        terrainShadows: Cesium.ShadowMode.DISABLED,
      });
      setCesiumViewer(cesiumViewer);
    }
  }, [context, Potree, Cesium, navigate, viewer, cesiumViewer, loaded]);

  useEffect(() => {
    const $ = window.$;
    if (!viewer || !Potree || !context) {
      return;
    }

    if (!cesiumViewer && context.options.enableProjection) {
      return;
    }
    viewer.setEDLEnabled(true);
    viewer.setFOV(60);
    viewer.setPointBudget(1_000_000);
    // viewer.setClipTask(Potree.ClipTask.SHOW_INSIDE);
    viewer.loadSettingsFromURL();

    if (context.options.enableProjection) {
      viewer.setBackground(null);
    }

    viewer.setDescription(`Point cloud demo`);

    viewer.loadGUI(() => {
      viewer.setLanguage("en");
      // $("#menu_appearance").next().show();
      $("#menu_tools").next().show();
      // $("#menu_scene").next().show();
      viewer.toggleSidebar();

      $("#sldPointBudget").slider({
        max: 3_000_000,
        step: 1000,
      });
    });

    // Load and add point cloud to scene
    // Potree.loadPointCloud("http://5.9.65.151/mschuetz/potree/resources/pointclouds/uni_heidelberg/dechen_cave/cloud.js", "Dechen Cave", function(e){
    Potree.loadPointCloud(context.cloudUrl, "Test").then(e => {
      let pointcloud = e.pointcloud;

      let material = pointcloud.material;

      material.activeAttributeName = "rgba";
      material.minSize = 2;
      material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
      let scene = viewer.scene;

      scene.addPointCloud(pointcloud);
      if (!context.options.enableProjection) {
        viewer.fitToScreen();
        setLoaded(true);
        return;
      }

      e.pointcloud.position.set(569277.402752, 5400050.599046, 0);
      e.pointcloud.rotation.set(0, 0, -0.035);

      material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
      material.size = 0.7;
      material.elevationRange = [0, 70];
      material.weightRGB = 1.0;
      material.weightElevation = 1.0;

      scene.view.position.set(570975.577, 5398630.521, 1659.311);
      scene.view.lookAt(570115.285, 5400866.092, 30.009);

      let pointcloudProjection =
        "+proj=utm +zone=33 +ellps=WGS84 +datum=WGS84 +units=m +no_defs";
      let mapProjection = proj4.defs("WGS84");

      window.toMap = proj4(pointcloudProjection, mapProjection);
      window.toScene = proj4(mapProjection, pointcloudProjection);

      {
        let bb = viewer.getBoundingBox();

        let minWGS84 = proj4(
          pointcloudProjection,
          mapProjection,
          bb.min.toArray()
        );
        let maxWGS84 = proj4(
          pointcloudProjection,
          mapProjection,
          bb.max.toArray()
        );
      }

      setLoaded(true);
    });
  }, [context, viewer, Potree, proj4, cesiumViewer]);

  useEffect(() => {
    if (context.options.enableProjection && viewer && cesiumViewer) {
      let cp = new Cesium.Cartesian3(
        4303414.154026048,
        552161.235598733,
        4660771.704035539
      );
      cesiumViewer.camera.setView({
        destination: cp,
        orientation: {
          heading: 10,
          pitch: -Cesium.Math.PI_OVER_TWO * 0.5,
          roll: 0.0,
        },
      });

      // const $ = window.$;
      // setTimeout(() => {
      // $("#potree_render_area > canvas:first-of-type").css("display", "none");
      // }, 8000);
      function loop(timestamp) {
        requestAnimationFrame(loop);

        viewer.update(viewer.clock.getDelta(), timestamp);

        viewer.render();

        if (window.toMap !== undefined) {
          const toMap = window.toMap;
          {
            let camera = viewer.scene.getActiveCamera();

            let pPos = new THREE.Vector3(0, 0, 0).applyMatrix4(
              camera.matrixWorld
            );
            let pRight = new THREE.Vector3(600, 0, 0).applyMatrix4(
              camera.matrixWorld
            );
            let pUp = new THREE.Vector3(0, 600, 0).applyMatrix4(
              camera.matrixWorld
            );
            let pTarget = viewer.scene.view.getPivot();

            let toCes = pos => {
              let xy = [pos.x, pos.y];
              let height = pos.z;
              let deg = toMap.forward(xy);
              let cPos = Cesium.Cartesian3.fromDegrees(...deg, height);

              return cPos;
            };

            let cPos = toCes(pPos);
            let cUpTarget = toCes(pUp);
            let cTarget = toCes(pTarget);

            let cDir = Cesium.Cartesian3.subtract(
              cTarget,
              cPos,
              new Cesium.Cartesian3()
            );
            let cUp = Cesium.Cartesian3.subtract(
              cUpTarget,
              cPos,
              new Cesium.Cartesian3()
            );

            cDir = Cesium.Cartesian3.normalize(cDir, new Cesium.Cartesian3());
            cUp = Cesium.Cartesian3.normalize(cUp, new Cesium.Cartesian3());

            cesiumViewer.camera.setView({
              destination: cPos,
              orientation: {
                direction: cDir,
                up: cUp,
              },
            });
          }

          let aspect = viewer.scene.getActiveCamera().aspect;
          if (aspect < 1) {
            let fovy = Math.PI * (viewer.scene.getActiveCamera().fov / 180);
            cesiumViewer.camera.frustum.fov = fovy;
          } else {
            let fovy = Math.PI * (viewer.scene.getActiveCamera().fov / 180);
            let fovx = Math.atan(Math.tan(0.5 * fovy) * aspect) * 2;
            cesiumViewer.camera.frustum.fov = fovx;
          }
        }

        cesiumViewer.render();
      }
      if (!loopActive) {
        requestAnimationFrame(loop);
        setLoopActive(true);
      }
    }
  }, [
    Cesium,
    cesiumViewer,
    context.options.enableProjection,
    THREE.Vector3,
    viewer,
    loopActive,
  ]);

  return (
    <div
      className="potree_container"
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
      }}
    >
      <div
        id="potree_render_area"
        ref={ref}
        style={{
          backgroundImage: "url('potree/resources/images/background.jpg')",
        }}
      >
        <div
          id="cesiumContainer"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "green",
          }}
        ></div>
      </div>
      <div id="potree_sidebar_container"></div>
    </div>
  );
};

export default PotreeViewer;
