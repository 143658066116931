import { useContext, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";

import { Link } from "react-router-dom";
import { CloudContext } from "../../App";
import pointCloudData from "../../assets/data/pointCloudData.json";
import Icon from "../../components/Icon/Icon";

const Markers = () => {
  const { context, setOptions, setCloudUrl, setCurrentLocation } =
    useContext(CloudContext);

  const map = useMap();

  useEffect(() => {
    if (context.options.flyTo) {
      const { flyTo } = context.options;
      map.flyTo(flyTo);
      setOptions({ flyTo: undefined });
    }

    if (context.options.enableProjection) {
      setOptions({ enableProjection: undefined });
    }
  }, [context.options, setOptions, map]);

  return (
    <>
      {pointCloudData.map(
        ({ position, description, descriptionLong, url, options }) => {
          return (
            <Marker position={position}>
              <Popup minWidth={500} maxWidth={500} autoClose closeOnClick>
                <h2 className="popup-header">{description}</h2>
                <p className="popup-description">{descriptionLong}</p>
                <Link to="/view-cloud">
                  <button
                    className="popup-button"
                    onClick={() => {
                      setCloudUrl(url);
                      setCurrentLocation(position);
                      if (options) {
                        setOptions(options);
                      }
                    }}
                  >
                    Go to map preview
                  </button>
                </Link>
              </Popup>
            </Marker>
          );
        }
      )}
    </>
  );
};

const Map = () => {
  const { context } = useContext(CloudContext);

  return (
    <div className="App">
      <MapContainer
        center={context.currentLocation}
        zoom={13}
        scrollWheelZoom
        style={{ height: "100vh" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Markers />
      </MapContainer>
    </div>
  );
};

export default Map;
