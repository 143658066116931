import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Map from "./pages/Map";
import PotreeViewer from "./pages/PotreeViewer";
import { createContext, useState } from "react";
import Header from "./components/Header/Header";
import pointCloudData from "./assets/data/pointCloudData.json";

export const CloudContext = createContext("");

function App() {
  const [context, setContext] = useState({
    cloudUrl: "",
    options: {},
    // currentLocation: { lng: 15.965, lat: 48.764 },
    currentLocation: pointCloudData[0].position,
  });

  const setCloudUrl = name => setContext(prev => ({ ...prev, cloudUrl: name }));

  const setOptions = opts => {
    setContext(prev => ({
      ...prev,
      options: { ...prev.options, ...opts },
    }));
  };

  const setCurrentLocation = location =>
    setContext(prev => ({ ...prev, currentLocation: location }));

  const contextValue = {
    context,
    setOptions,
    setCloudUrl,
    setCurrentLocation,
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Header />
          <Map />
        </>
      ),
    },
    {
      path: "/view-cloud",
      element: (
        <>
          <Header />
          <PotreeViewer />
        </>
      ),
    },
  ]);
  return (
    <CloudContext.Provider value={contextValue}>
      <RouterProvider router={router} />
    </CloudContext.Provider>
  );
}

export default App;
